import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY_LOCALSTORAGE;
const rem = process.env.REACT_APP_REM;
const rem2 = process.env.REACT_APP_REM2;
const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const location = window.location;

export default function dataDecrypt(value) {
  const POSTURL = location.hostname === HOSTNAME ? rem2 : rem;

  try {
    const bytes = CryptoJS.AES.decrypt(value.CKIS, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.log('🚀🚀🚀🚀 ~ file: data-decrypt.js:9 ~ dataDecrypt ~ error:', error);
    document.location.href = POSTURL;
  }
}
