import { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  Grid,
  Divider,
  Chip,
  AccordionActions,
  Card,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TransitionGroup } from 'react-transition-group';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';

import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import EnhancedTable from './Tabla';
import Iconify from '../../../../components/Iconify';
import AddElementSections from './AddElementSections';     

// const FRUITS = ['1', '2', '3', '4', 'null', '🍉'];
const FRUITS = ['Nuevos', 'Por defecto', 'Primera'];
// const FRUITS = ['🍏 Apple', '🍌 Banana', '🍍 Pineapple', '🥥 Coconut', '🍉 Watermelon'];

const rows = [
  { id: 1, lastName: 'Desayunos', firstName: 'Jon', age: 'Nuevos' },
  { id: 2, lastName: 'Meriendas', firstName: 'Cersei', age: 'Nuevos' },
  { id: 3, lastName: 'Promo 1', firstName: 'Jaime', age: 'Nuevos' },
  { id: 4, lastName: 'Promo 1', firstName: 'Arya', age: 'Primera' },
  { id: 5, lastName: 'Pollo Enteros', firstName: 'Daenerys', age: 'Primera' },
  { id: 6, lastName: 'Pollos Criollos', firstName: 9, age: 'Primera' },
  { id: 7, lastName: 'Cena', firstName: 'Ferrara', age: 'Por defecto' },
  { id: 8, lastName: 'Helados', firstName: 'Rossini', age: 'Por defecto' },
  { id: 9, lastName: 'Postres', firstName: 'Harvey', age: 'Nuevos' },
];

const Accordion = styled((props) => <MuiAccordion elevation={0} {...props} />)(({ theme }) => ({
  // backgroundColor: 'rgba(0, 0, 0, .03)',
  // border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

RenderItem.propTypes = {
  name: PropTypes.string,
};

function RenderItem({ name, ...props }) {
  const { item, fruitsInBasket, handleRemoveFruit, expanded, handleChange } = props;
  const [dense, setDense] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const rows = fruitsInBasket;
  function botones({ ...props }) {
    return (
      <>
        <ButtonGroup variant="text" aria-label="Basic button group">
          <Button
            size="small"
            // variant="outlined"
            aria-label="delete"
            title="Eliminar sección"
            color="error"
            onClick={() => handleRemoveFruit(props.ite)}
          >
            <DeleteIcon />
          </Button>
          <Button
            size="small"
            id="fade-button"
            title="Más"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button>
        </ButtonGroup>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Ocultar</MenuItem>
          <MenuItem onClick={handleClose}>Editar</MenuItem>
        </Menu>
      </>
    );
  }
  function cajas({ item, ite, ...props }) {
    return (
      ite.age === item && (
        <ListItem key={ite.id} secondaryAction={botones({ ite })}>
          <Accordion sx={{ width: '90%' }} onChange={() => props.handleChange(ite.id)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Grid item xs={2} sm={4} md={4}>
                <Chip
                  label={ite.lastName}
                  // onClick={handleClick}
                  // onDelete={handleDelete}
                  // deleteIcon={<DeleteIcon />}
                  variant="outlined"
                />
              </Grid>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                    01:00 pm{' '}
                  </Typography>{' '}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <Iconify icon="fluent-mdl2:date-time-2" sx={{ mr: 0.5, width: 16, height: 16 }} />
                    01/05/2024
                  </Typography>{' '}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                    07:00 pm{' '}
                  </Typography>{' '}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <Iconify icon="fluent-mdl2:date-time-2" sx={{ mr: 0.5, width: 16, height: 16 }} />
                    01/05/2024
                  </Typography>{' '}
                </Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography sx={{ color: 'text.secondary' }}>
                  <Iconify icon="ic:round-account-tree" sx={{ mr: 0.5, width: 16, height: 16 }} />4
                </Typography>{' '}
              </Grid>
            </AccordionSummary>
          </Accordion>
        </ListItem>
      )
    );
  }
  function cajasview({ item, ite, ...props }) {
    return (
      ite.age === item && (
        <ListItem key={ite.id}>
          <Accordion sx={{ width: '100%' }} onChange={() => props.handleChange(ite.id)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              {/* <Typography>{ite.lastName} </Typography> */}
              <Chip
                label={ite.lastName}
                sx={{ mt: 0, ml: 1, width: '30%', flexShrink: 10 }}
                // onClick={handleClick}
                // onDelete={handleDelete}
                // deleteIcon={<DeleteIcon />}
                variant="outlined"
              />
              {/* <Typography sx={{ mt: 0, ml: 2, width: '33%', flexShrink: 0 }}>{ite.lastName}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{ite.firstName}</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              {/* {ite.lastName} {ite.firstName} {ite.age} */}
              <EnhancedTable />
            </AccordionDetails>
            {/* <AccordionActions>
              <Button>Cancelar</Button>
              <Button>Agregar</Button>
            </AccordionActions> */}
          </Accordion>
        </ListItem>
      )
    );
  }

  return (
    <>
      <Divider textAlign="left" sx={{ p: 0, mt: 2.5 }}>
        {/* <Chip label={item} size="small" /> */}
        {item}
      </Divider>
      <Card sx={{ p: 0, mt: 2.5 }}>
        {
          fruitsInBasket.map((ite) =>
            expanded !== ite.id
              ? cajas({ item, ite, expanded, handleChange })
              : cajasview({ item, ite, expanded, handleChange })
          ) // fin Map
        }
      </Card>
    </>
  );
}

export default function TransitionGroupExample({ ...pro }) {
  const [fruitsInBasket, setFruitsInBasket] = useState(rows);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => {
    if (id === expanded) {
      setExpanded(false);
    } else {
      setExpanded(id);
    }
  };

  const handleAddFruit = () => {
    const nextHiddenItem = rows.find((i) => !fruitsInBasket.includes(i));

    if (nextHiddenItem) {
      setFruitsInBasket((prev) => [nextHiddenItem, ...prev]);
    }
  };

  const handleRemoveFruit = (item) => {
    setFruitsInBasket((prev) => [...prev.filter((i) => i !== item)]);
  };

  // const addFruitButton = (
  //   <Button variant="contained" disabled={fruitsInBasket.length >= FRUITS.length} onClick={handleAddFruit}>
  //     Add fruit to basket
  //   </Button>
  // );

  return (
    <div>
      <List sx={{ mt: 1.5 }}>
        <TransitionGroup>
          {FRUITS.map((item) => (
            <RenderItem
              key={item}
              item={item}
              fruitsInBasket={fruitsInBasket}
              handleRemoveFruit={handleRemoveFruit}
              expanded={expanded}
              handleChange={handleChange}
            />
          ))}
        </TransitionGroup>
      </List>
    </div>
  );
}
