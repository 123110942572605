import * as React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

Sizes.propTypes = {
  name: PropTypes.string,
};
export default function Sizes({ name, ...pros }) {

  
  return (
    <Stack   >
      <Autocomplete
        multiple
        id="size-small-standard-multi"
        size="small"
        options={pros.arrayIn}
        getOptionLabel={(option) => (option ? option.name : option)}
        defaultValue={pros.arrayIn ? pros.arrayIn : false}
        renderInput={(params) => (
          <TextField {...params} variant="standard"  placeholder="Lista de ingredientes" />
        )}
      />
    </Stack>
  );
}
