// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Catalogo',
    path: '/dashboard/products',
    icon: getIcon('bxs:food-menu'),
  },
  {
    title: 'QR',
    path: '/dashboard/product',
    icon: getIcon('ic:sharp-qr-code'),
  },
  
  {
    title: 'user',
    path: '/a',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'blog',
    path: '/a',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'login',
    path: '/c',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'register',
    path: '/d',
    icon: getIcon('eva:person-add-fill'),
  }, 
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  }, 

];

export default navConfig;
