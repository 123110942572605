// routes
import Router from './routes';
// theme
import { AuthProvider } from './sections/auth/AuthProvider';
import ThemeProvider from './theme';

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------
// window.addEventListener(
//   'message',
//   (e) => {
//     if (e.origin !== 'http://localhost:3000') return;
//     console.log(e.data);
//   }
// );

// window.addEventListener(
//   'message',
//   (e) => {
//     if (e.origin !== 'https://kartaqr.com') return;
//     console.log('Mensaje recibido: ', e.data);
//   },
//   false
// );
// const authToken = localStorage.getItem('authToken');
// console.log(authToken); // 'tu_token_secreto'
// console.log("Mensaje recibido");

// window.addEventListener('message', event => {
//   // IMPORTANT: check the origin of the data!
//   if (event.origin.startsWith('http://localhost:3000')) {
//       // The data was sent from your site.
//       // Data sent with postMessage is stored in event.data:
//       console.log(event.data);
//   }  });

// window.addEventListener('message', event => {
//   // IMPORTANT: check the origin of the data!
//   if (event.origin.startsWith('http://localhost:3001')) {
//       // The data was sent from your site.
//       // Data sent with postMessage is stored in event.data:
//       console.log(event.data);
//   }  });

export default function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </ThemeProvider>
    </AuthProvider>
  );
}
