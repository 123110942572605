import { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import { useAuth } from './sections/auth/AuthProvider';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
// component
import ProgressCircular from './components/progress/ProgressCircular';
import ProtectedRoute from './protectedRoute/ProtectedRoutes';
// ----------------------------------------------------------------------

// <EndPoin />
function EndPoin() {
  const auth = useAuth();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setOpen(!auth.isAuthenticated);
    }
  },[auth.isAuthenticated]);

  return (
    <>
      <ProgressCircular open={open} />
      {useRoutes([
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },
            { path: 'user', element: <User /> },
            { path: 'products', element: <Products /> },
            { path: 'blog', element: <Blog /> },
          ],
        },
        {
          path: '/',
          element: <ProtectedRoute />,
          children: [
            { path: '/', element: <Navigate to="/dashboard/app" /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ])}
    </>
  );
}

export default function Router() {
  return (
    <>
      <EndPoin />
    </>
  );
}
