import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { IMaskInput } from 'react-imask';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

// form
import { useFormContext, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

// @mui
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InputMask from 'react-input-mask';

// mascara -----------------------------------------------------------

const NumericFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------
TextFieldPrice.propTypes = {
  name: PropTypes.string,
};

export default function TextFieldPrice({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask
          {...field}
          mask={NumericFormatCustom}
          value={field.value}
          onChange={field.onChange}
          maskChar=""
          disabled={other.DisabledButton}
          {...other}
        >
          {(e) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                inputComponent: NumericFormatCustom,
                startAdornment: <InputAdornment position="start">{other.icon}</InputAdornment>,
              }}
              {...other}
            />
          )}
        </InputMask>
      )}
    />
  );
}
