import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextareaAutosize } from '@mui/base';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Divider, Chip, Link, Typography, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ImageUploading from 'react-images-uploading';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';

// --------------------------------------------------------------
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { styled } from '@mui/material/styles';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import TextField from '@mui/material/TextField';
import {
  FormProvider,
  RHFTextField,
  SelectDocumenttype,
  SelectAll,
  PhoneInputnumber,
  TextFieldicon2,
  TextFieldicon,
  TextFieldPrice,
  AutoCompletSize,
  TextFieldDocument,
  InputCalend,
} from '../../../components/hook-form';

import { fCurrency } from '../../../utils/formatNumber';
import PhotoPlate from './UploadPhotoPlate';

// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import useSelectConsult from '../../../hooks/consult/useConsultCombo';

// ----------------------------------------------------------------------

const companytype = process.env.REACT_APP_companytype;

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

const typecm = [
  { name: 'contorno todo', id: 1994 },
  { name: 'Pollo salsas', id: 1972 },
];

const defaultValues = {
  namePlate: '',
  price: '',
  contorno: { id: '', name: '' },
  //  Username: '',
  // di: '',
  // dob: '',
  description: '',
  // ingre: {},
};
export default function ShopProductCard({ name, ...other }) {
  const [day, setDob] = useState();
  const [form, setForm] = useState();
  const [arrayIngre, setArrayIngre] = useState([]);
  const [nada, setNada] = useState('');
  const [formCount, setFormCount] = useState(defaultValues);
  const [idr, setIdr] = useState(0);
  const [fil, setFil] = useState();
  const [OpenAlert, setOpenAlert] = useState(false);
  //
  const [images, setImages] = useState([]);

  const RegisterSchema = Yup.object().shape({
    namePlate: Yup.string()
      .required('El nombre del plato no puede estar vacio.')
      .min(2, 'lo minimo son 4 caracteres')
      .max(50, 'Maximo 50 caracteres.'),
    price: Yup.string().required('Agrega un precio').min(1, 'ponle precio a tu plato').max(12, 'Maximo de 10 digitos '),
    contorno: Yup.object()
      .shape({ id: Yup.string().required(''), name: Yup.string().required('') })
      .required('campo obligatorio'),
    description: Yup.string().required('Detalle brevemente su producto'),
    // ingre: Yup.string(),
  });
  //

  const methods = useForm({ resolver: yupResolver(RegisterSchema), formCount });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    register,
  } = methods;

  watch((e) => setForm(e.ingre));

  const setingredientes = () => {
    setIdr(idr + 2);
    arrayIngre.push({ name: `#${form}`, id: idr });
    setNada(false);
    setForm();
    setFormCount(formCount);
  };
  const verifyarray = arrayIngre.slice(-1)[0]; // .name.includes(`#${form}`);

  window.setTimeout(() => {
    if (verifyarray) {
      setFil(verifyarray.name);
    }
  }, 200);

  useEffect(() => {
    //    setActivaButton(form === '1' ? false : '1');
    if (fil !== `#${form}`) {
      setNada(form);
    } else {
      setForm();
      setNada(false);
    }
  }, [form, fil]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  // Para enviar a la endPoid
  const onSubmit = (values) => {
    const cantimages = images.length;

    if (cantimages > 0) {
      const valuePut = {
        form: values,
        imag: images,
        listingred: arrayIngre,
      };
      // const r = values.push({ img: images });
      console.log(valuePut);
    } else {
      setOpenAlert(true);
      window.setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldicon2
              id="namePlate"
              name="namePlate"
              label="Nombre plato"
              variant="standard"
              placeholder="Dale un nombre a tu plato"
              //  icon={<FoodBankIcon />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldPrice
              id="price"
              name="price"
              label="Precio"
              variant="standard"
              placeholder="0.00"
              fullWidth
              // icon={<AlternateEmailIcon />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectAll
              name="contorno"
              apiconect={typecm}
              Optins={'Sin resultados'}
              label="contorno"
              width="100%"
              variant="standard"
            />
          </Grid>
          <Divider />
          <Grid item sx={{ mt: 2, mb: 2 }} xs={12} sm={15}>
            {!OpenAlert && <PhotoPlate onChange={onChange} images={images} product={other.product} />}
            <Collapse in={OpenAlert}>
              <Alert variant="outlined" severity="warning">
                Carga una imagen{' '}
              </Alert>
            </Collapse>
          </Grid>

          <Grid item xs={15}>
            <TextFieldicon
              id="description"
              name="description"
              label="Describe tu producto"
              multiline
              rows={2}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldicon
              id="ingre"
              name="ingre"
              label="Ingredientes"
              variant="standard"
              value={!nada ? '' : nada}
              placeholder="curcuma"

              // icon={<AlternateEmailIcon />}
            />
          </Grid>

          <Grid sx={{ mt: 2, mb: 1 }} item xs={12} sm={6}>
            <Button
              //
              disabled={!form}
              fullWidth
              variant="outlined"
              onClick={setingredientes}
            >
              agregar ingredientes
            </Button>
          </Grid>

          <Grid sx={{ mt: 2, mb: 1 }} item xs={12}>
            <AutoCompletSize
              fullWidth
              width="100%"
              id="ingredients"
              name="ingredients"
              label="lista de ingredientes"
              variant="standard"
              placeholder="con que preparaste"
              arrayIn={arrayIngre}
              //  icon={<FoodBankIcon />}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={other.handleClose}>Cancelar</Button>
          <LoadingButton
            // disabled={!aceptarterminos}
            // name={name}
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Crear
          </LoadingButton>
          {/* <Button onClick={onSubmit} variant="outlined" color="success" type="submit">
            Crear
          </Button> */}
        </DialogActions>
      </FormProvider>
    </>
  );
}
