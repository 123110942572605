import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddModalSetions({ value, openModal, ...pros }) {
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
  };

  return (
    <div>
      <Dialog open={pros.open} onClose={pros.handleClose}>
        <DialogTitle>Crea una sección</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Al crear una sección podras incluir tus platos y bebidas por categorias, horarios y existencias. Dale un nombre Corto
            y acorde con tu idea.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Nombre de sección"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={pros.handleClose}>Cancelar</Button>
          <Button variant="outlined" color="success" type="submit">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
