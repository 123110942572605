import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ShopProductCard from '../../products/ProductCardAddPlatos';
import PRODUCTS from '../../../../_mock/products';

export default function AddModalSetions({ value, openModal, ...pros }) {
  // const [open, setOpen] = React.useState(false);

  const Submit = () => {
    console.log('formulario enviado');
    // setOpen(false);
  };

  return (
    <div>
      <Dialog open={pros.open} onClose={pros.handleClose}>
        <DialogTitle>Creación de plato</DialogTitle>
        <DialogContent >
          <ShopProductCard product={PRODUCTS[3]} onSubmit={Submit} handleClose={pros.handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
