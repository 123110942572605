import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, CardMedia, Link, Typography, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import ImageUploading from 'react-images-uploading';
import LoadingButton from '@mui/lab/LoadingButton';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// --------------------------------------------------------------
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { styled } from '@mui/material/styles';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import TextField from '@mui/material/TextField';
import {
  FormProvider,
  RHFTextField,
  SelectDocumenttype,
  TypeCompanySelect,
  PhoneInputnumber,
} from '../../../components/hook-form';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import useSelectConsult from '../../../hooks/consult/useConsultCombo';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// ----------------------------------------------------------------------
const colorgradiente = 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)';
const colortrasnparente = 'linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 70%, rgba(0,0,0,0) 100%)';
const companytype = process.env.REACT_APP_companytype;

PhotoPlate.propTypes = {
  product: PropTypes.object,
};

function srcset(image, Width, Height, rows = 1, cols = 1) {
  return {
    src: `${image}`,
    // src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    // srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}
export default function PhotoPlate({ ...other }) {

  const cantimages = other.images.length;
  const viewcollage = cantimages !== 2 ? 1 : 2;
  const medida = cantimages > 1 ? '80%' : '50px';
  const maxNumber = 69;

  return (
    <>
      <ImageUploading
        multiple
        value={other.images}
        onChange={other.onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          // write your building UI
          <div>
            {cantimages < 3 && (
              <Button
                //
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                variant="outlined"
                onClick={onImageUpload}
              >
                {cantimages}/3 <AddPhotoAlternateIcon />
              </Button>
            )}
            {cantimages > 0 && (
              <Card
                sx={{
                  // cursor: 'pointer',
                  py: cantimages > 0 ? 0 : 5,
                  boxShadow: 0,
                  textAlign: 'center',
                }}
                variant="outlined"
              >
                <Box sx={{ pt: cantimages === 1 ? '80%' : medida, position: 'relative' }}>
                  <ImageListItem
                    sx={{
                      top: 0,
                      objectFit: 'cover',
                      position: 'absolute',
                      // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                    }}
                  >
                    <ImageList
                      // rowHeight={200}
                      sx={{
                        // width: 500,
                        height: cantimages > 3 ? 0 : 480,
                        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                        transform: 'translateZ(0)',
                      }}
                      variant="quilted"
                      cols={cantimages > 1 ? 2 : 1}
                      gap={3}
                    >
                      {imageList.map((image, index) => (
                        <ImageListItem key={index} cols={index ? 1 : 1} rows={index ? viewcollage : 2}>
                          <img src={image.data_url} alt={image.file.name} />
                          <ImageListItemBar
                            sx={{
                              background: colorgradiente,
                            }}
                            position="top"
                            actionIcon={
                              <IconButton onClick={() => onImageRemove(index)} sx={{ color: 'white' }}>
                                <DeleteForeverIcon />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </ImageListItem>
                </Box>
              </Card>
            )}
          </div>
        )}
      </ImageUploading>
    </>
  );
}
