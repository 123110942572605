const API_URL = process.env.REACT_APP_API_AUTH; // Conection Api
const url = process.env.REACT_APP_API_LOGOUT; // Conection Api
// const url = 'logout';

export default async function requestRevokToken(repToken) {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      headers: {
        referrerPolicy: 'unsafe_url',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://kartaqr.com',
        mode: 'same-origin',
      },
      body: JSON.stringify(repToken),
    });
    
    console.log('desde la api revokatoken',response);

    if (response.ok) {
      const json = await response.json();
      if (json.error) {
        throw new Error(json.error);
      } else {
        return json;
      }
    } else {
      throw new Error('Unable to refresh access token.');
    }
  } catch (error) {
    console.log('🚀X🚀X🚀XX----- ~ file: reqRevToken ~ conexion ~ error:', error);
  }
}
