import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, IconButton, Collapse, Toolbar, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// components
import { LoadingButton } from '@mui/lab';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { AddSecctions } from '../sections/@dashboard/add';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  // obtener datos del usuario
  //
  const theme = useTheme();
  const [indexvalue, setValue] = useState(0);
  const [open, setOpen] = useState(true);

  const toggleChange = (newValue) => {
    setValue(newValue);
    setOpen(!open);
  };

  const toggleDrawer = () => {
    setOpen(!open);
    setValue(0);
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        {theme ? (
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome {'nameUser'}
          </Typography>
        ) : (
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>
        )}

        {/* {indexvalue === 'Primary' ? ( */}
        <Collapse in={open} timeout={'auto'}>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
            <AppWidgetSummary
              toggleChange={toggleChange}
              index={4}
              title="Estructura"
              total={234}
              color="error"
              icon={'uis:window-section'}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                toggleChange={toggleChange}
                index={1}
                title="Secciones"
                total={13}
                color="info"
                icon={'icon-park-outline:right-branch'}
              />
              {/* // se podria colocar un icono de Bransh */}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Portada" total={1} icon={'ic:sharp-menu-book'} /></Grid> */}
            {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Platos" total={20} color="warning" icon={'ic:outline-room-service'} /></Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                toggleChange={toggleChange}
                index={2}
                title="Platos"
                total={20}
                color="warning"
                icon={'mdi:food-turkey'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                toggleChange={toggleChange}
                index={3}
                title="Bebidas"
                total={1}
                icon={'ic:outline-free-breakfast'}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bebidas" total={1} icon={'ic:sharp-menu-book'} /></Grid> */}

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                toggleChange={toggleChange}
                index={4}
                title="Comandas"
                total={234}
                color="success"
                icon={'bxs:calendar-edit'}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AppNewsUpdate
                title="Nuevas ordenes"
                list={[...Array(5)].map((_, index) => ({
                  id: faker.datatype.uuid(),
                  title: faker.name.jobTitle(),
                  description: faker.name.jobTitle(),
                  image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                  postedAt: faker.date.recent(),
                }))}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppTasks
                title="Check list"
                list={[
                  { id: '1', label: 'Create FireStone Logo' },
                  { id: '2', label: 'Add SCSS and JS files if required' },
                  { id: '3', label: 'Stakeholder Meeting' },
                  { id: '4', label: 'Scoping & Estimations' },
                  { id: '5', label: 'Sprint Showcase' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppTrafficBySite
                title="Traffic by Site"
                list={[
                  {
                    name: 'FaceBook',
                    value: 323234,
                    icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                  },
                  {
                    name: 'Google',
                    value: 341212,
                    icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                  },
                  {
                    name: 'Linkedin',
                    value: 411213,
                    icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                  },
                  {
                    name: 'Twitter',
                    value: 443232,
                    icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Collapse>
        {/* ) : ( */}
        <Collapse in={!open} timeout={500} sx={{ p: 2, pr: 0 }}>
          <Grid container spacing={3}>
            <Button variant="outlined" onClick={toggleDrawer} size="large" sx={{ color: 'text.secondary', bottom: 15 }}>
              <ChevronLeftIcon />
              Atrás
            </Button>
            <AddSecctions value={indexvalue} />
          </Grid>
        </Collapse>
        {/* )} */}
      </Container>
    </Page>
  );
}
